import { FlowBase, identificationTypes } from "./flow-base";
import * as events from '../events'
import genericEvents from './generic-events';

class VodacomTwoClickOTPMZ extends FlowBase {
    handleIntegratorIframeIdentity() {

        //since heIframeIdentity has no error handled add a timeout then call identifyOnError
        this.iframeTimeout = window.setTimeout(() => {
            this.logger.info('[Vodacom MZ] Iframe timed out.')
            this.checkIdentify()
        }, this.identifyTimeout * 1000);

        this.integrator.customHeIframeIdentifyBaseUrl(this.identifyEndpoint,
            {
                return: window.location.href,
            }
        );
    }

    bindClickEvents() {
        this.dispatcher.addEventListener(events.FIRST_CLICK, this.subscribe.bind(this));
        this.dispatcher.addEventListener(events.SECOND_CLICK, this.confirm.bind(this));
        this.dispatcher.addEventListener(events.CONTENT_CLICK, this.handleContentClick.bind(this))
        this.dispatcher.addEventListener(events.TRY_FOR_FREE, this.handleTryForFreeEvent.bind(this))
        //OTP events are triggered in UIControl.js
        this.dispatcher.addEventListener(events.OTP_CHALLENGE_VALIDATE, this.handleOtpChallengeValidate.bind(this))
        this.dispatcher.addEventListener(events.OTP_CHALLENGE_SUBMIT, this.handleOtpChallengeSubmit.bind(this))
        this.dispatcher.addEventListener(events.CONSENT_TICKBOX_CHANGED, this.consentTickboxTicked.bind(this))
        genericEvents.bindWifiClickEvent(this);
        genericEvents.bindFlowExitEvent(this);
        genericEvents.bindLanguageSwitcherEvent(this);
    }
    //Overriden to show custom error messages
    subscribeOnError(response) {
        this.uiControl.hideElement(this.uiControl.controls.spinner);
        if (response.status_code === 403) {
            this.logger.debug(`[Subscribe Error] Flow (${this.name}): fraud detected`, response);
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
            this.setFlowStep(this.flowSteps.FRAUD);
            this.dispatcher.dispatchEvent(events.FRAUD_DETECTED, {
                reference: response.reference,
                status_code: response.status_code,
            });
        } else if (response.status_code === 402) {
            this.logger.debug(`[Subscribe Error] Flow (${this.name}): insufficent funds`, response);
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                status_code: response.status_code,
                error: this.returnErrorMessageFromResponse(response),
                response_string: response.response_string || null,
            });
            this.uiControl.showErrorMessage('INSUFFICENT_FUNDS', 'Retry');
        } else {
            this.logger.error(`[Subscribe Error] Flow (${this.name}): received error from integrator`, response);
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                status_code: response.status_code,
                error: this.returnErrorMessageFromResponse(response),
                response_string: response.response_string || null,
            });
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
        }
    }
}

const VodacomTwoClickOTPMZFlowModule = new VodacomTwoClickOTPMZ('Twoclick OTP Vodacom MZ', identificationTypes.IFRAME, {
    subscribeClick: true,
    OTP: true,
    otpChallengeValidateURL: 'otp/subscribe',
    optChallengeSubmitURL: 'otp/generate',
    inactiveTimeout: true,
    subscribeEndpoint: 'subscribe',
    identifyEndpoint: 'he/identify',
    identifyTimeout: 40,
})
window.FlowModule = VodacomTwoClickOTPMZFlowModule;
