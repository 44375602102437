import * as events from '../events';
import Utilities from '../utilities';
import genericEvents from './generic-events';

export const identificationTypes = Object.freeze({
    IFRAME: 'IFRAME',
    PREID: 'PRE-ID',
    AJAX: 'AJAX',
    OTP: 'OTP',
    DEBUG: 'DEBUG',
})

export class FlowBase {
    /**
     *
     * @param {string} name
     * @param {identificationTypes} identification
     * @param {Object} args //An object that overrides any of the fields in the class after they have been overridden by LP.js
     */
    constructor(name, identification, args) {
        this.setupClassProps()
        //check class has been setup correctly
        if (!identification in identificationTypes) {
            throw new Error('Identification method not found.')
        }

        if (typeof args != 'object') {
            throw new Error("Args must be included.")
        }
        this.name = name;
        this.identification = identification;
        this.args = args;
    }

    setupClassProps() {
        this.identificationMethods = {
            [identificationTypes.IFRAME]: this.iframeIdentify.bind(this),
            [identificationTypes.AJAX]: this.ajaxIdentify.bind(this),
            [identificationTypes.PREID]: this.preIdentify.bind(this),
            [identificationTypes.OTP]: this.otpIdentify.bind(this),
            [identificationTypes.DEBUG]: this.noIdentify.bind(this)
        }

        this.uiControl = {}
        this.flowSteps = {
            SPINNER: 'spinner',
            INIT: 'init',
            CONFIRM: 'confirm',
            SUBSCRIBE: 'subscribe',
            ERROR: 'error',
            FRAUD: 'fraud',
            SUBSCRIPTION_FAILED: 'subscription failed',
            SUBSCRIPTION_SUCCEEDED: 'subscription success',
            SUBSCRIPTION_SUCCEEDED_CONTAINER: 'subscription success container',
            TIMEOUT: 'timeout',
            WIFI: 'wifi',
            CONTENT_CLICK: 'content click',
            USERNAME: 'username',
            USERNAME_ERROR: 'username error',
            HE_OTP_CHALLENGE: 'he otp challenge',
            HE_OTP_VALIDATE: 'he otp validate',
            OTP_CHALLENGE: 'otp challenge',
            OTP_VALIDATE: 'otp validate',
            OTP_SUBMIT: 'otp submit',
            OTP_VALIDATE_ERROR: 'otp validate error',
            OTP_SUBMIT_ERROR: 'otp submit error',
            PRE_REDIRECT: 'pre redirect',
        }
        this.tracker = {}
        this.dispatcher = {}
        this.integrator = {}
        this.territory = ''
        this.integratorIdentifyUrl = ''
        this.logger = {}
        this.internalCall = false
        this.timeoutLimit = 180000 // 3 minutes
        this.plan = ''
        this.productSlug = ''
        this.metadata = {}
        this.otpChallengeValidateURL = 'lp/otp/subscribe'
        this.optChallengeSubmitURL = 'lp/otp/generate'
        this.userContentBlocked = false;
        this.userContentOverride = false;
        this.identified = false;
        this.trackConversions = false;
        this.identifyCallFinished = false;
        this.redirecting = false;
        this.subscribeEndpoint = 'lp/he/subscribe';
        this.identifyEndpoint = 'lp/he/identify'
        this.identity = ""
        this.productUrl = ""
        this.locale = ""
        this.integratorIdentity = ""
        this.identifyTimeout = 10
        this.heOtp = false
        this.consentTickbox = false
        this.consentTickboxAutofilled = false;
        this.inactiveTimeout = false;
        this.inactiveTimeoutSet = false;
        this.suggestedUsername = '';
        this.usernameFlow = false;
        this.chosenUsername = '';
        this.heAuthToken = '';
    }

    overwriteClassProps() {
        for (const [key, value] of Object.entries(this.args)) {
            this[key] = value
        }
    }

    handleTryForFree() {
        //if a query string of triedfree is in query parameters then disable the button to avoid a loop
        const urlParams = new URLSearchParams(window.location.search);
        const tryForFree = urlParams.get('triedfree');
        if (tryForFree) {
            this.uiControl.hideElement(this.uiControl.controls.tryForFree)
        }

    }

    //This is a continuation from the constructor after LP.js has added all the modules
    preInit() {
        if (this.metadata['page_attributes-no-identify']) {
            this.identification = identificationTypes.DEBUG;
        }

        this.overwriteClassProps()

        //Sub is first, then confirm then content if all three are enabled if just confirm then just show confirm click
        //If sub is enabled then show both
        //All flows have a pre subscribe so if  that option is left undefined then assume its needed and enable it
        //Then its called when OTP is validated or when the subscribeUser func is called
        this.preSubscribe = typeof this.preSubscribe === 'undefined' ? true : this.preSubscribe
        this.handleTryForFree()
        if (this.usernameFlow && this.suggestedUsername !== undefined) {
            this.uiControl.prefillInput(this.uiControl.controls.username.username, this.suggestedUsername);
        }
    }

    overridableInit() {
        //This is a pure abstract function for the user to override not to be used by this class
    }

    init() {
        this.preInit()
        this.overridableInit()
        this.integrator.appendBaseData({ territory: this.territory });
        this.logger.debug(`Flow (${this.name}): initialising flow`);
        this.bindClickEvents();
        this.bindTrackingEvents();
        this.showFirstStep();
        this.identify();
        this.setInactiveTimeout();
        this.handleConsentClick();
    }

    handleConsentClick(){
        if(this.consentTickbox){
            this.uiControl.controls.consent.consentTickbox.checked = this.consentTickboxAutofilled
        }
    }

    setInactiveTimeout() {
        if (this.inactiveTimeout) {
            this.clearInactiveTimeout();

            this.timeout = window.setTimeout(() => {
                this.handleSetPageStateTimeout()
            }, this.timeoutLimit);
            this.logger.debug(`Flow (${this.name}): set inactive timeout ${this.timeout}`);
        }
    }

    clearInactiveTimeout() {
        if (this.timeout && this.inactiveTimeout) {
            this.logger.debug(`Flow (${this.name}): clearing timeout ${this.timeout}`);
            window.clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    handleSetPageStateTimeout() {
        if (this.inactiveTimeout) {
            this.logger.debug(`Flow (${this.name}): handling inactive timeout`);
            this.setFlowStep(this.flowSteps.TIMEOUT);
            this.tracker.track(events.PAGE_TIMEOUT);
            this.uiControl.showErrorMessage('SESSION_TIMEOUT_ERROR', 'Retry');
        }
    }

    identify() {
        this.identificationMethods[this.identification].bind(this)()
    } // Not overridable, configurable based on type of ID. if successful, show subscribe step, if not, go to backup method


    checkIdentify() {
        if (!this.returnIsIdentifyCallFinished()) {
            this.identifyOnError({
                identity: '',
                identified: false,
                status_code: 'N/A'
            })
        }
    }

    handleIntegratorIframeIdentity() {
        //since heIframeIdentity has no error handled add a timeout then call identifyOnError
        this.iframeTimeout = window.setTimeout(() => {
            this.checkIdentify()
        }, this.identifyTimeout * 1000);

        this.integrator.customHeIframeIdentifyBaseUrl(this.identifyEndpoint,
            {
                returnUrl: window.location.href,
            }
        );
    }

    iframeIdentify() {
        if(!this.internalCall){
            this.handleIntegratorIframeIdentity()
        }
        window.addEventListener('message', (event) => {
            //event is the response data sent from the flow
            //call on message here and use the onSuccess onError defined above since the heResponse is the same
            this.onMessage(event, this.identifyOnSuccess.bind(this), this.identifyOnError.bind(this))
        }, false);
    }

    ajaxIdentify() {
        if (!this.internalCall){
            this.integrator.heIdentifyGetRequest({
                return: window.location.origin + window.location.pathname,
            }, this.identifyOnSuccess.bind(this), this.identifyOnError.bind(this));
        }
    }

    getSuccessQueryParam(){
        return Utilities.getQueryParameter(null, 'success');
    }

    getIdentityQueryParam(){
        return Utilities.getQueryParameter(null, 'identity');
    }

    preIdentify() {
        const success = this.getSuccessQueryParam();
        const identity = this.getIdentityQueryParam();
        if (identity && success == '1') {
            this.identifyCallFinished = true;
            let alteredURL = Utilities.removeQueryParameter('identity', window.location.href);
            alteredURL = Utilities.removeQueryParameter('success', alteredURL);
            window.history.pushState({}, null, alteredURL);
            this.identifyOnSuccess({
                identity: identity,
                identified: true,
            })
        }else{
            this.identifyOnError(
                {
                    identity: identity,
                    identified: false,
                    status_code: 'N/A'
                })
        }
    }

    otpIdentify() {
        this.setFlowStep(this.flowSteps.OTP_CHALLENGE)
    }

    noIdentify() {
        this.identifyOnSuccess({
            identity: "debug_identity",
            identified: true,
        })
    }

    onMessage(event, onSuccess, onError) {
        //check the iframe event and then call the correct one
        const res = event.data;
        if (res.status_code == 200) {
            onSuccess(res)
        } else {
            this.identifyCallFinished = true;
            onError(res)
        }
    }

    returnErrorMessageFromResponse(response){
        if(typeof response.message != 'undefined'){
            return response.message;
        }
        if(typeof response.error != 'undefined'){
            return response.error;
        }
        return "ERROR MESSAGE COULD NOT BE FOUND";
    }

    identifyOnSuccess(response) { // overridable
        if(this.iframeTimeout){
            window.clearTimeout(this.iframeTimeout)
        }
        if (response.forbidden) {
            this.uiControl.displayMessage('CONTENT_BLOCK_ERROR');
            return;
        }
        this.identity = response.identity;
        this.identified = response.identified;
        if (this.identified) {
            this.tracker.track(events.IDENTIFY_SUCCESS);
            this.logger.info('[Identity Success] id response', response);
            if(response.status === "EXISTS"){
                this.redirecting = true;
                // we set this field in all blocks to avoid isIdentifyCallFinished resolving before this.redirect is set
                this.identifyCallFinished = true;
                this.tracker.track(events.SUBSCRIPTION_EXISTS);
                this.redirectToProduct(response.jwt ?? response.identity);
                return;
            }
            this.identifyCallFinished = true;
            this.logger.debug('[Identity Success] id response', response);
            this.userContentBlocked = (typeof response.content_blocked !== 'undefined' && (response.content_blocked == 1 || response.content_blocked == "Y" || response.content_blocked == true))
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.showFirstStep()
        } else {
            this.identifyCallFinished = true;
            this.logger.error(`[Identity Failure] id response`, response);
            this.tracker.track(events.IDENTIFY_FAILURE, {
                reference: response.reference,
                status_code: response.status_code,
            });
            this.identifyBackup()
        }
    }

    identifyOnError(response) { // overridable, this should call identifybackup()
        if(this.iframeTimeout){
            window.clearTimeout(this.iframeTimeout)
        }
        this.identity = response.identity;
        this.identified = response.identified;
        this.identifyCallFinished = true;
        //change this to use our system
        this.logger.error(`[Identity Error] Flow (${this.name}): received error from integrator`, response);
        this.tracker.track(events.IDENTIFY_ERROR, {
            reference: response.reference,
            status_code: response.status_code,
        });
        this.identifyBackup()
    }
    identifyBackup() {
        if (this.OTP) {
            this.tracker.track(events.OTP_CHALLENGE);
            this.setFlowStep(this.flowSteps.OTP_CHALLENGE)
        } else {
            //OTP not enabled just do WIFI
            this.tracker.track(events.SHOW_WIFI);
            this.setFlowStep(this.flowSteps.WIFI)
            this.uiControl.showWifiFlowWithRefreshPage();
        }
    }

    showFirstStep() { // Not overridable, configured based on 1click, 2click etc. show correct button (this will be configured on init - this.firstStep)
        if (this.usernameFlow) {
            this.setFlowStep(this.flowSteps.USERNAME)
        } else if(this.subscribeClick) {
            this.setFlowStep(this.flowSteps.SUBSCRIBE)
        } else if (this.heOtp) {
            this.setFlowStep(this.flowSteps.HE_OTP_CHALLENGE)
        } else {
            this.setFlowStep(this.flowSteps.CONFIRM)
        }
    }

    usernameSubmit() {
        this.setInactiveTimeout();
        if (this.uiControl.controls.username.username.value) {
            this.integrator.validateUsernameRequest({
                "username": this.uiControl.controls.username.username.value,
            }, this.usernameSubmitOnSuccess.bind(this), this.usernameSubmitOnError.bind(this))
        }
    }

    usernameSubmitOnSuccess(response) {
        this.chosenUsername = this.uiControl.controls.username.username.value; // TODO: this is not a good way of doing it. it works for now.
        this.tracker.track(events.USERNAME_SUBMIT, {data: {username: this.chosenUsername}})
        this.setFlowStep(this.flowSteps.HE_OTP_CHALLENGE)
    }

    usernameSubmitOnError(response) {
        this.tracker.track(events.USERNAME_SUBMIT_ERROR)
        this.setFlowStep(this.flowSteps.USERNAME_ERROR)
    }

    usernameChange() {
        this.uiControl.controls.username.container.classList.add("loading");
        this.setInactiveTimeout();
            this.integrator.changeUsernameRequest({
                "host": window.location.hostname,
                "url": window.location.pathname.substring(1, window.location.pathname.length),
            }, this.usernameChangeOnSuccess.bind(this), this.usernameChangeOnError.bind(this))
    }

    usernameChangeOnSuccess(response) {
        window.setTimeout(() => {
            this.uiControl.controls.username.container.classList.remove("loading");
        }, 1000);
        this.chosenUsername = response.username; // TODO: this is not a good way of doing it. it works for now.
        this.uiControl.controls.username.username.value = response.username;
        this.tracker.track(events.USERNAME_CHANGE)
        this.setFlowStep(this.flowSteps.USERNAME)
    }

    usernameChangeOnError(response) {
        this.uiControl.controls.username.container.classList.remove("loading");
        this.tracker.track(events.USERNAME_SUBMIT_ERROR)
        this.setFlowStep(this.flowSteps.USERNAME_ERROR)
    }

    subscribe() {
        this.setInactiveTimeout();
        this.tracker.track(events.FIRST_CLICK)
        this.setFlowStep(this.flowSteps.CONFIRM)
    } // overridable, this function simply needs to show the confirm button

    confirm() { // overridable, this function needs to subscribe then redirect to product or call contentClick
        if(!this.consentTickbox || (this.consentTickbox && this.uiControl.controls.consent.consentTickbox.checked)){
            this.clearInactiveTimeout();
            this.tracker.track(events.SECOND_CLICK)
            this.handleSubscribe()
        }
    }

    handleContentClick() { // overridable, this function needs to subscribe then redirect to product
        this.setInactiveTimeout();
        this.tracker.track(events.CONTENT_CLICK)
        this.userContentOverride = true;
        if (this.subscribeClick) {
            this.setFlowStep(this.flowSteps.SUBSCRIBE)
        } else {
            this.setFlowStep(this.flowSteps.CONFIRM)
        }
    }

    //Need a func that handles just identifyCallFinished before userContentBlocked
    returnIsIdentifyCallFinished(){
        return this.identifyCallFinished;
    }

    isIdentifyCallFinished() {
        // page is in redirect state; anything polling for ID call completion shouldn't happen
        if (this.redirecting) {
            return false
        }

        // no content click, just check ID'd
        if (!this.contentClick) {
            return this.identifyCallFinished;
        }
        // content click enabled, user content blocked, check for override
        if (this.identifyCallFinished && this.userContentBlocked) {
            return this.userContentOverride;
        }

        // content click enabled, user NOT content blocked, check ID'd
        return this.identifyCallFinished;
    }

    subscribeOnError(response) {
        this.uiControl.hideElement(this.uiControl.controls.spinner);
        if (response.status_code === 403) {
            this.logger.error(`[Subscribe Error] Flow (${this.name}): fraud detected`, response);
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
            this.setFlowStep(this.flowSteps.FRAUD);
            this.dispatcher.dispatchEvent(events.FRAUD_DETECTED, {
                reference: response.reference,
                status_code: response.status_code,
                error: this.returnErrorMessageFromResponse(response),
                response_string: response.response_string || null,
            });
        } else if (response.status_code === 402) {
            this.logger.error(`[Subscribe Error] Flow (${this.name}): insufficent funds`, response);
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                status_code: response.status_code,
                error: this.returnErrorMessageFromResponse(response),
                response_string: response.response_string || null,
            });
            this.uiControl.displayCustomMessage('You have insufficient funds. Please recharge and try again');
        } else {
            this.logger.error(`[Subscribe Error] Flow (${this.name}): received error from integrator`, response);
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                status_code: response.status_code,
                error: this.returnErrorMessageFromResponse(response),
                response_string: response.response_string || null,
            });
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
        }
    }

    subscribeOnSuccess(response) {
        this.logger.info(`[Subscribe Success] Flow (${this.name}): received integrator response`, response);

        if (response.status === 'SUCCESS') {
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_SUCCEEDED);
            if (response.new) {
                this.dispatcher.dispatchEvent(events.SUBSCRIPTION_SUCCEEDED, {
                    jwt: response.jwt,
                    reference: response.reference,
                });
            } else {
                this.tracker.track(events.SUBSCRIPTION_EXISTS, {
                    data: {
                        reference: response.reference,
                    },
                });
                this.redirectToProduct(response.jwt);
            }
        } else {
            this.logger.error('[Subscribe On Success] Status is not SUCCESS', response)
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                error: this.returnErrorMessageFromResponse(response),
            });
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
        }
    }

    subscribeUser() {
        if (this.identified) {
            this.logger.info('[Subscribe User] user is identified; attempting subscription', {
                identity: this.identity,
                metadata: this.metadata
            });

            if (this.contentClick && this.userContentBlocked) {
                this.metadata.user_content_override = this.userContentOverride;
            } else {
                delete this.metadata.user_content_override;
            }

            const data = {
                identity: this.identity,
                fraud_detection_id: this.metadata.fraud_transaction_id,
                metadata: this.metadata,
                mixpanel_tracking_id: this.metadata.event_tracking_id,
            };

            if (this.usernameFlow) {
                data['username'] = this.chosenUsername;
            }
            this.integrator.LPSubscribeRequest(data, this.subscribeEndpoint, this.subscribeOnSuccess.bind(this), this.subscribeOnError.bind(this));
        } else {
            if (this.identification == identificationTypes.DEBUG) {
                this.otpValidateSuccess({"status": "SUCCESS"})
                return
            }
            this.identifyBackup()
        }
    }

    handleSubscribe() {
        if (this.preSubscribe) {
            this.dispatcher.dispatchEvent(events.PRE_SUBSCRIBE);
        }
        this.setFlowStep(this.flowSteps.SPINNER)//show spinner as soon as they press subscribe
        Utilities.waitForConditionAndExecute(
            this.isIdentifyCallFinished.bind(this),
            this.subscribeUser.bind(this),
        );
    }

    redirectToProduct(jwt) {//overridable
        // remove unload message
        window.onbeforeunload = null;

        let redirectUrl = this.generateProductUrl(jwt)

        this.logger.info(`Flow (${this.name}): redirecting to product`, redirectUrl);
        this.tracker.track(events.REDIRECT_TO_PRODUCT, {
            data: {
                redirectUrl: redirectUrl,
            },
        });

        window.location.assign(redirectUrl);
    }

    generateProductUrl(jwt) {
        let redirectUrl = this.productUrl;
        const queryConcat = (redirectUrl.indexOf('?') !== -1) ? '&' : '?';

        return `${this.productUrl}${queryConcat}auth=${jwt}&locale=${this.locale}`;
    }

    otpValidateSuccess(response) {
        this.logger.info(`[OTP Validate Success] Flow (${this.name}): received integrator response`, response);

        //track that the OTP was sent with the correct code regardless of if it actually worked

        if (response.status == "SUCCESS"){
            this.tracker.track(events.OTP_VALIDATE_SUCCESS)
            if (response.new) {
                this.dispatcher.dispatchEvent(events.SUBSCRIPTION_SUCCEEDED, {
                    jwt: response.jwt,
                    reference: response.reference,
                });
            } else {
                this.tracker.track(events.SUBSCRIPTION_EXISTS, {
                    data: {
                        reference: response.reference,
                    },
                });
                this.redirectToProduct(response.jwt);
            }
        } else {
            this.otpValidateError(response)
        }
    };

    otpValidateError(response) {
        this.logger.error(`[OTP Validate] Flow (${this.name}): received error from integrator`, response);
        const errorMessage = typeof response.message !== 'undefined' ? response.message : response.error
        this.tracker.track(events.OTP_VALIDATE_FAILURE, {data: {message: errorMessage}})
        this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
            reference: response.reference,
            error: this.returnErrorMessageFromResponse(response)
        });
        this.setFlowStep(this.flowSteps.OTP_VALIDATE_ERROR)
    };

    otpSubmitError(response) {
        this.logger.error(`[OTP Submit Error] Flow (${this.name}): received error from integrator`, response);
        this.setFlowStep(this.flowSteps.OTP_SUBMIT_ERROR);
        this.tracker.track(events.OTP_GENERATE_FAILURE, {
            data: {
                reference: response.reference,
            },
        });
    };

    otpSubmitSuccess(response) {
        this.logger.info(`[OTP Submit Success] Flow (${this.name}): received integrator response`, response);

        if (response.status === 'SUCCESS') {
            this.token = response.token;
            this.setFlowStep(this.flowSteps.OTP_VALIDATE);
            this.tracker.track(events.OTP_GENERATE_SUCCESS, {
                data: {
                    code_token: response.token,
                    reference: response.reference,
                },
            });
        } else {
            this.setFlowStep(this.flowSteps.OTP_SUBMIT_ERROR);
            this.tracker.track(events.OTP_GENERATE_FAILURE, {
                data: {
                    reference: response.reference,
                },
            });
        }
    };

    heOtpSubmitError(response) {
        this.logger.error(`[OTP Submit Error] Flow (${this.name}): received error from integrator`, response);
        this.setFlowStep(this.flowSteps.HE_OTP_SUBMIT_ERROR);
        this.tracker.track(events.OTP_GENERATE_FAILURE, {
            data: {
                reference: response.reference,
            },
        });
    };

    heOtpSubmitSuccess(response) {
        this.logger.info(`[OTP Submit Success] Flow (${this.name}): received integrator response`, response);

        if (response.status === 'SUCCESS') {
            this.token = response.token;
            this.setFlowStep(this.flowSteps.HE_OTP_VALIDATE);
            this.tracker.track(events.OTP_GENERATE_SUCCESS, {
                data: {
                    code_token: response.token,
                    reference: response.reference,
                },
            });
        } else {
            this.setFlowStep(this.flowSteps.OTP_SUBMIT_ERROR);
            this.tracker.track(events.OTP_GENERATE_FAILURE, {
                data: {
                    reference: response.reference,
                },
            });
        }
    };


    handleOtpChallengeValidate() {
        this.logger.info(`Flow (${this.name}): handling challenge validation`);
        this.tracker.track(events.SECOND_CLICK)

        if (this.identification == identificationTypes.DEBUG) {
            this.otpValidateSuccess({"status": "SUCCESS"})
            return
        }

        if (this.uiControl.controls.otp.code.value === '') {
            this.tracker.track(events.OTP_VALIDATE_FAILURE, {data: {message: "empty_code"}})
            return
        }

        this.setFlowStep(this.flowSteps.OTP_VALIDATE)

        if (this.preSubscribe) {
            this.dispatcher.dispatchEvent(events.PRE_SUBSCRIBE);
        }

        const data = {
            plan: this.plan,
            product: this.productSlug,
            metadata: this.metadata,
            token: this.token ?? this.identity,
            code: this.uiControl.controls.otp.code.value,
            identity: this.identity,
        }

        if (this.usernameFlow) {
            data['username'] = this.chosenUsername;
        }
        data['mixpanel_tracking_id'] = typeof this.metadata['mixpanel_tracking_id'] !== 'undefined' ? this.metadata['mixpanel_tracking_id'] : this.metadata['evinaTransactionID']
        data['locale'] = this.locale;
        data['territory'] = this.territory;//Added these fields to ensure that OTP Validation is correctly configured and has all the fields that the integrator expects
        this.integrator.LPOtpSubscribeCustomRequest(data, this.otpChallengeValidateURL, this.otpValidateSuccess.bind(this), this.otpValidateError.bind(this));
    }

    handleOtpChallengeSubmit() {
        this.setInactiveTimeout();
        var payload = {
            plan: this.plan,
            product: this.productSlug,
            metadata: this.metadata,
        };
        if (this.heOtp && this.identified) {
            payload['identity'] = this.identity
        } else {
            if (this.uiControl.controls.otp.msisdn.value === '') {
                return;
            }
            payload['msisdn'] = this.uiControl.controls.otp.msisdn.value
        }

        this.logger.info(`Flow (${this.name}): handling challenge submit`);
        this.setFlowStep(this.flowSteps.OTP_SUBMIT)
        this.integrator.LPOtpGenerateCustomRequest(payload, this.optChallengeSubmitURL, this.otpSubmitSuccess.bind(this), this.otpSubmitError.bind(this));
    }

    handleHeOtpChallengeSubmit() {
        this.tracker.track(events.FIRST_CLICK)
        this.setInactiveTimeout();
        var payload = {
            plan: this.plan,
            product: this.productSlug,
            metadata: this.metadata,
        };
        if (this.identified) {
            payload['identity'] = this.identity
        } else {
            this.logger.warn("no identity")
        }
        if (this.identification === identificationTypes.DEBUG) {
            this.heOtpSubmitSuccess({
                status: "SUCCESS",
                code_token: "token",
                reference: "debug"
            })
            return
        }
        this.logger.info(`Flow (${this.name}): handling challenge submit`);
        payload['mixpanel_tracking_id'] = this.mixpanel_tracking_id;
        payload['metadata']['token'] = this.identity;
        this.integrator.LPOtpGenerateCustomRequest(payload, this.optChallengeSubmitURL, this.heOtpSubmitSuccess.bind(this), this.heOtpSubmitError.bind(this));
    }

    handleTryForFreeEvent() {
        window.onbeforeunload = null;
        let redirectUrl = this.productUrl;
        const pageQuery = (window.location.href.indexOf('?') !== -1) ? window.location.href.split('?')[1] + '&' : ''
        redirectUrl = `${this.productUrl}?${pageQuery}locale=${this.locale}&triedfree=true`;

        this.logger.debug(`Flow (${this.name}): redirecting to product`, redirectUrl);
        this.tracker.track(events.REDIRECT_TO_PRODUCT, {
            data: {
                redirectUrl: redirectUrl,
            },
        });

        window.location.assign(redirectUrl);
    }

    consentTickboxTicked(){
        this.tracker.track(events.CONSENT_TICKBOX_CHANGED, {
            data: {
                ticked: this.uiControl.controls.consent.consentTickbox.checked
            }
        })
    }

    bindClickEvents() {
        this.dispatcher.addEventListener(events.FIRST_CLICK, this.subscribe.bind(this));
        this.dispatcher.addEventListener(events.SECOND_CLICK, this.confirm.bind(this));
        this.dispatcher.addEventListener(events.USERNAME_SUBMIT, this.usernameSubmit.bind(this));
        this.dispatcher.addEventListener(events.CONTENT_CLICK, this.handleContentClick.bind(this))
        this.dispatcher.addEventListener(events.TRY_FOR_FREE, this.handleTryForFreeEvent.bind(this))
        //OTP events are triggered in UIControl.js
        this.dispatcher.addEventListener(events.HE_OTP_CHALLENGE_SUBMIT, this.handleHeOtpChallengeSubmit.bind(this));
        this.dispatcher.addEventListener(events.OTP_CHALLENGE_VALIDATE, this.handleOtpChallengeValidate.bind(this))
        this.dispatcher.addEventListener(events.OTP_CHALLENGE_SUBMIT, this.handleOtpChallengeSubmit.bind(this))
        this.dispatcher.addEventListener(events.CONSENT_TICKBOX_CHANGED, this.consentTickboxTicked.bind(this))
        this.dispatcher.addEventListener(events.USERNAME_CHANGE, this.usernameChange.bind(this))
        genericEvents.bindWifiClickEvent(this);
        genericEvents.bindFlowExitEvent(this);
    }


    handleSubscriptionFailed(event) {
        this.logger.error('Failed to subscribe', event)
        this.tracker.track(events.SUBSCRIPTION_FAILED, {
            data: {
                reference: event.reference,
                status_code: event.status_code,
                error_message: event.error,
            }
        });
    }

    handleSubscriptionSucceeded(event) {
        this.logger.info('SubscriptionSucceeded redirecting to product', event)
        if (this.googleTagTracking) {
            this.loadGoogleTag(this.googleTags.SUCCESS);
        }
        this.tracker.track(events.SUBSCRIPTION_SUCCEEDED, {
            data: {
                subscription: event.jwt,
                reference: event.reference,
            },
        });

        if (this.trackConversions) {
            const redirector = () => {
                this.redirectToProduct(event.jwt);
            };
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_SUCCEEDED_CONTAINER)
            window.setTimeout(redirector.bind(this), 3500);
            this.uiControl.displayMessage('Please wait while you are redirected to the service');
            this.tracker.conversion({
                subscription: event.jwt,
            });
        } else {
            this.redirectToProduct(event.jwt);
        }
    }

    handleFraudDetected(event) {
        this.tracker.track(events.FRAUD_DETECTED, {
            data: {
                reference: event.reference,
                status_code: event.status_code,
                error_message: event.error,
            },
        });
        this.handleSubscriptionFailed(event)
    }


    bindTrackingEvents() {
        this.dispatcher.addEventListener(events.FRAUD_DETECTED, this.handleFraudDetected.bind(this));
        this.dispatcher.addEventListener(events.SUBSCRIPTION_FAILED, this.handleSubscriptionFailed.bind(this));
        this.dispatcher.addEventListener(events.SUBSCRIPTION_SUCCEEDED, this.handleSubscriptionSucceeded.bind(this));
    }

    setFlowStep(flowStep) {
        this.logger.debug(`Flow (${this.name}): changing step from ${this.step} to ${flowStep}`);
        this.step = flowStep;
        this.uiControl.setPageState(flowStep);
        //Some flowsteps are triggered in this flow for CSS purpose and therefore aren't included here. They can be added in the future though.
        switch (flowStep) {
            case this.flowSteps.USERNAME:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.showElement(this.uiControl.controls.username.container);
                this.uiControl.showElement(this.uiControl.controls.username.username);
                this.uiControl.showElement(this.uiControl.controls.username.submit);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                break;
            case this.flowSteps.SUBSCRIBE:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.showElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.username.container);
                break;
            case this.flowSteps.CONFIRM:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.wifi);
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.showElement(this.uiControl.controls.confirm);
                this.uiControl.showElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.showElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.username.container);
                this.tracker.track(events.CTA_SHOWN)
                break;
            case this.flowSteps.CONTENT_CLICK:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.showElement(this.uiControl.controls.contentClick.button);
                this.uiControl.showElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.username.container);
                break;
            case this.flowSteps.HE_OTP_CHALLENGE:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.username.container);
                this.uiControl.showElement(this.uiControl.controls.otp.container);
                this.uiControl.showElement(this.uiControl.controls.otp.heotp.container);
                this.uiControl.showElement(this.uiControl.controls.otp.heotp.button);
                break;
            case this.flowSteps.HE_OTP_VALIDATE:
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.otp.challenge);
                this.uiControl.hideElement(this.uiControl.controls.otp.error);
                this.uiControl.hideElement(this.uiControl.controls.username.container);
                this.uiControl.hideElement(this.uiControl.controls.otp.heotp.container);
                this.uiControl.showElement(this.uiControl.controls.otp.container);
                this.uiControl.showElement(this.uiControl.controls.otp.validate);
                break;
            case this.flowSteps.OTP_CHALLENGE:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.otp.error);
                this.uiControl.showElement(this.uiControl.controls.otp.container);
                this.uiControl.showElement(this.uiControl.controls.otp.challenge);
                this.uiControl.showElement(this.uiControl.controls.otp.msisdn);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.username.container);
                break;
            case this.flowSteps.OTP_VALIDATE:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.otp.challenge);
                this.uiControl.hideElement(this.uiControl.controls.otp.error);
                this.uiControl.showElement(this.uiControl.controls.otp.validate);
                this.uiControl.hideElement(this.uiControl.controls.username.container);
                break;
            case this.flowSteps.WIFI:
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.username.container);
                this.uiControl.hideElement(this.uiControl.controls.otp.container);
                this.uiControl.showElement(this.uiControl.controls.wifi);
                break;
            case this.flowSteps.OTP_SUBMIT:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.showElement(this.uiControl.controls.spinner);
                break;
            case this.flowSteps.SPINNER:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.showElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                break;
            case this.flowSteps.OTP_VALIDATE_ERROR:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.showElement(this.uiControl.controls.otp.error);
                break;
            case this.flowSteps.OTP_SUBMIT_ERROR:
                //Everything that happens in challenge
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                //But show the error instead
                this.uiControl.showElement(this.uiControl.controls.otp.challengeError);
                break;
            case this.flowSteps.HE_OTP_SUBMIT_ERROR:
                //Everything that happens in challenge
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                //But show the error instead
                this.uiControl.showElement(this.uiControl.controls.otp.error);
                break;
            case this.flowSteps.SUBSCRIPTION_SUCCEEDED_CONTAINER:
                this.uiControl.showElement(this.uiControl.controls.success.container);
                break;
            case this.flowSteps.FRAUD:
            case this.flowSteps.SUBSCRIPTION_FAILED:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                break;
            case this.flowSteps.TIMEOUT:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                break;
            case this.flowSteps.PRE_REDIRECT:
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.otp.container);
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.username.container);
        }
    }
}
